<template>
  <page-main
    title="Group your skills together"
    :aside="[
      'Group skills into related groups. Try for 3-4 skills per group.',
      'Describe how you have used these skills in the past in your accomplishments.',
      'How will you apply these skills going forward?'
    ]"
    nextText="Back to Strengths"
    buttonTabIndex="4"
    eventName="nav"
    @nav="nav('/Strengths')"
    @save="save()"
  >
    <v-container>
      <v-row>
        <v-col cols="12" class="r-col">
          <r-multi-select
            label="List 3 to 4 skills"
            :optionsList="skillsList"
            :selected.sync="skills"
            titleText=""
            autofocus
            tabindex="1"
          ></r-multi-select>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-textarea
            v-model="showCase"
            label="What work experiences or accomplishments do you have that showcased these skills?"
            tabindex="2"
            rows="3"
            autogrow
          ></r-textarea>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-textarea
            v-model="howApply"
            label="How will you apply these skills in your targeted industry or company?"
            tabindex="3"
            rows="3"
            autogrow
          ></r-textarea>
        </v-col>
      </v-row>
    </v-container>
  </page-main>
</template>

<script>
// @ is an alias to /src
import { mapFields } from "vuex-map-fields";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "StrengthsCategory",
  data: () => ({
    skillsList: []
  }),
  components: {},
  mounted() {
    let pageKey = this.$route.params.key;
    if (this.key != pageKey) {
      if (!this.doesKeyExist(pageKey)) {
        this.createEntry({ key: pageKey });
      }
      this.key = pageKey;
    }

    this.skillsList.push(...this.hardSkills.map(x => x.text));
    this.skillsList.push(...this.softSkills.map(x => x.text));
    this.skillsList.push(...this.licensesCerts.map(x => x.text));
    this.skillsList.push(...this.selectedAdjectives.map(x => x.text));
    this.skillsList.push(...this.selectedRoles.map(x => x.text));
    this.skillsList.push(...this.languages.map(x => x.language));
  },
  methods: {
    save() {
      this.display = this.skills
        .map(x => x?.text)
        .reduce((a, c) => a + (a.length > 0 ? ", " : "") + c, "");

      this.saveStrengths();
    },
    ...mapMutations("strengths", ["createEntry"]),
    ...mapActions("strengths", ["saveStrengths"]),
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    }
  },
  computed: {
    ...mapGetters("work", ["doesKeyExist"]),
    ...mapFields("hardSkill", ["hardSkills"]),
    ...mapFields("softSkill", ["softSkills"]),
    ...mapFields("licenseCert", ["licensesCerts"]),
    ...mapFields("adjective", ["selectedAdjectives"]),
    ...mapFields("role", ["selectedRoles"]),
    ...mapFields("language", ["languages"]),
    ...mapFields("strengths", [
      "key",
      "skills",
      "showCase",
      "howApply",
      "display"
    ])
  }
};
</script>
